// Display Page Information
export const LOAD_CUSTOMER_INFO_SUCCESS = 'LOAD_CUSTOMER_INFO_SUCCESS';
export const LOAD_CUSTOMER_MONTHLY_SUMMARY_SUCCESS = 'LOAD_CUSTOMER_MONTHLY_SUMMARY_SUCCESS';
export const LOAD_CUSTOMER_MONTHLY_SUMMARY_TABLE_SUCCESS = 'LOAD_CUSTOMER_MONTHLY_SUMMARY_TABLE_SUCCESS';

// Extended Monthly Summary Information
export const LOAD_CUSTOMER_ESIM_STATUS_SUCCESS = 'LOAD_CUSTOMER_ESIM_STATUS_SUCCESS';

// Insight Page Information
export const LOAD_ESIM_INSIGHTS_TABLE_REQUEST = 'LOAD_ESIM_INSIGHTS_TABLE_REQUEST';
export const LOAD_ESIM_INSIGHTS_TABLE_SUCCESS = 'LOAD_ESIM_INSIGHTS_TABLE_SUCCESS';
export const LOAD_ESIM_INSIGHTS_TABLE_ERROR = 'LOAD_ESIM_INSIGHTS_TABLE_ERROR';
export const LOAD_ESIM_INSIGHTS_TABLE_BY_EID_SUCCESS = 'LOAD_ESIM_INSIGHTS_TABLE_SUCCESS';
export const LOAD_ESIM_INSIGHTS_TABLE_BY_ICCID_SUCCESS = 'LOAD_ESIM_INSIGHTS_TABLE_SUCCESS';
export const LOAD_ESIM_INSIGHTS_TABLE_BY_ALL_SUCCESS = 'LOAD_ESIM_INSIGHTS_TABLE_SUCCESS';


// Management Page Information
export const LOAD_ESIM_MANAGEMENT_TABLE_REQUEST = 'LOAD_ESIM_MANAGEMENT_TABLE_REQUEST';
export const LOAD_ESIM_MANAGEMENT_TABLE_SUCCESS = 'LOAD_ESIM_MANAGEMENT_TABLE_SUCCESS';
export const LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_ALL_SUCCESS = 'LOAD_ESIM_MANAGEMENT_TABLE_SUCCESS';
export const LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_EID_SUCCESS = 'LOAD_ESIM_MANAGEMENT_TABLE_SUCCESS';
export const LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_DEFAULT_ICCID_SUCCESS = 'LOAD_ESIM_MANAGEMENT_TABLE_SUCCESS';
export const LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_STATUS_SUCCESS = 'LOAD_ESIM_MANAGEMENT_TABLE_SUCCESS';
export const LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_EID_AND_STATUS_SUCCESS = 'LOAD_ESIM_MANAGEMENT_TABLE_SUCCESS';
export const LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_DEFAULT_ICCID_AND_STATUS_SUCCESS = 'LOAD_ESIM_MANAGEMENT_TABLE_SUCCESS';
export const LOAD_ESIM_MANAGEMENT_TABLE_ERROR = 'LOAD_ESIM_MANAGEMENT_TABLE_ERROR';

//Export Excel
export const LOAD_EXPORT_MANAGEMENT_TABLE_REQUEST = 'LOAD_EXPORT_MANAGEMENT_TABLE_REQUEST';
export const LOAD_EXPORT_MANAGEMENT_TABLE_ALL_SUCCESS = 'LOAD_EXPORT_MANAGEMENT_TABLE_SUCCESS';
export const LOAD_EXPORT_MANAGEMENT_TABLE_ERROR = 'LOAD_EXPORT_MANAGEMENT_TABLE_ERROR';
export const LOAD_EXPORT_INSIGHTS_TABLE_REQUEST = 'LOAD_EXPORT_INSIGHTS_TABLE_REQUEST';
export const LOAD_EXPORT_INSIGHTS_TABLE_ALL_SUCCESS = 'LOAD_EXPORT_INSIGHTS_TABLE_SUCCESS';
export const LOAD_EXPORT_INSIGHTS_TABLE_ERROR = 'LOAD_EXPORT_INSIGHTS_TABLE_ERROR';

//Alerts Page Information
export const LOAD_ALERTS_TABLE_REQUEST = 'LOAD_ALERTS_TABLE_REQUEST';
export const LOAD_ALERTS_TABLE_ERROR = 'LOAD_ALERTS_TABLE_ERROR';
export const LOAD_ALERTS_TABLE_SUCCESS = 'LOAD_ALERTS_TABLE_SUCCESS';

// Security
export const COGNITO_ATTRIBUTES_LOADED_SUCCESS = 'LOAD_USER_ATTRIBUTES_SUCCESS';

// ????
export const COGNITO_ATTRIBUTES_TRANSFORMED_SUCCESS = 'COGNITO_ATTRIBUTES_TRANSFORMED_SUCCESS';

// User Details Information
export const USER_DETAILS_LOADED_SUCCESS = 'USER_DETAILS_LOADED_SUCCESS';
export const USER_GROUPS_LOADED_SUCCESS = 'USER_GROUPS_LOADED_SUCCESS';

// Organization style
export const SAVE_ORGANIZATION_STYLE_INFO_SUCCESS = 'SAVE_ORGANIZATION_STYLE_INFO_SUCCESS';

// Search Parameters
export const SEARCH_PARAMETERS_UPDATED_SUCCESS = 'SEARCH_PARAMETERS_UPDATED_SUCCESS';

// Customer Navigation Menu
export const LOAD_ALLOWED_CUSTOMERS_SUCCESS = 'LOAD_ALLOWED_CUSTOMERS_SUCCESS';

// application tokens
export const LOAD_APPLICATION_TOKENS_TABLE_SUCCESS = 'LOAD_APPLICATION_TOKENS_TABLE_SUCCESS';
export const LOAD_APPLICATION_TOKENS_TABLE_ERROR = 'LOAD_APPLICATION_TOKENS_TABLE_ERROR';
export const CREATE_APPLICATION_TOKEN_SUCCESS = 'CREATE_APPLICATION_TOKEN_SUCCESS';
export const CREATE_APPLICATION_TOKEN_ERROR = 'CREATE_APPLICATION_TOKEN_ERROR';
export const DELETE_APPLICATION_TOKEN_SUCCESS = 'DELETE_APPLICATION_TOKEN_SUCCESS';
export const DELETE_APPLICATION_TOKEN_ERROR = 'DELETE_APPLICATION_TOKEN_ERROR';
export const CLEAN_NEW_BORN_APPLICATION_TOKEN = 'CLEAN_NEW_BORN_APPLICATION_TOKEN';

// api doc
export const LOAD_ESIM_API_DOC_SUCCESS = 'LOAD_ESIM_API_DOC_SUCCESS';
export const LOAD_ESIM_API_DOC_ERROR = 'LOAD_ESIM_API_DOC_ERROR';

// sim management
export const LOAD_SIM_MANAGEMENT_TABLE_SUCCESS = 'LOAD_SIM_MANAGEMENT_TABLE_SUCCESS';
export const LOAD_SIM_MANAGEMENT_TABLE_ERROR = 'LOAD_SIM_MANAGEMENT_TABLE_ERROR';

// tagging
export const LOAD_SIM_TAG_MANAGEMENT_TABLE_REQUEST = 'LOAD_SIM_TAG_MANAGEMENT_TABLE_REQUEST';
export const LOAD_SIM_TAG_MANAGEMENT_TABLE_ERROR = 'LOAD_SIM_TAG_MANAGEMENT_TABLE_ERROR';
export const LOAD_ALLOWED_CUSTOMER_TAGS_SUCCESS = 'LOAD_ALLOWED_CUSTOMER_TAGS_SUCCESS';
export const LOAD_SIM_TAG_MANAGEMENT_TABLE_SUCCESS = 'LOAD_SIM_TAG_MANAGEMENT_TABLE_SUCCESS';
export const STORE_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS = 'STORE_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS';
export const PUT_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS = 'PUT_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS';
export const POST_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS = 'POST_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS';

// support
export const POST_CUSTOMER_SUPPORT_FORM_SUCCESS = 'POST_CUSTOMER_SUPPORT_FORM_SUCCESS';

// user Preferences
export const PUT_CUSTOMER_SUPPORT_PREF_EMAILS_SUCCESS = 'PUT_CUSTOMER_SUPPORT_PREF_EMAILS_SUCCESS';
export const GET_CUSTOMER_SUPPORT_PREF_EMAILS_SUCCESS = 'GET_CUSTOMER_SUPPORT_PREF_EMAILS_SUCCESS';

// sim details
export const LOAD_SIM_DETAIL_BY_EID_SUCCESS = 'LOAD_SIM_DETAIL_BY_EID_SUCCESS';
export const POST_ACTIVATE_SIM_BY_EID_SUCCESS = 'POST_ACTIVATE_SIM_BY_EID_SUCCESS';
export const POST_TERMINATE_SIM_BY_EID_SUCCESS = 'POST_TERMINATE_SIM_BY_EID_SUCCESS';
export const POST_RESUME_SIM_BY_EID_SUCCESS = 'POST_RESUME_SIM_BY_EID_SUCCESS';
export const POST_SUSPEND_SIM_BY_EID_SUCCESS = 'POST_SUSPEND_SIM_BY_EID_SUCCESS';
export const DELETE_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS = 'DELETE_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS';