import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function allowedCustomersTagsReducer(state = initialState.allowedCustomerTags, action) {
    switch (action.type) {
    case types.LOAD_ALLOWED_CUSTOMER_TAGS_SUCCESS:
        return action.allowedCustomerTags;
    default:
        return state;
    }
}