import * as types from './actionTypes';
import React from 'react';
import {Row, Col, Alert} from 'reactstrap';

export const esimApiDocXhrError = message => {
    return {type: types.LOAD_ESIM_API_DOC_ERROR, message};
};

export const esimManagementXhrError = message => {
    return {type: types.LOAD_ESIM_MANAGEMENT_TABLE_ERROR, message};
};

export const exportManagementXhrError = message => {
    return {type: types.LOAD_EXPORT_MANAGEMENT_TABLE_ERROR, message};
};

export const esimInsightXhrError = message => {
    return {type: types.LOAD_ESIM_INSIGHTS_TABLE_ERROR, message};
};

export const applicationTokensTableXhrError = message => {
    return {type: types.LOAD_APPLICATION_TOKENS_TABLE_ERROR, message};
};

export const createApplicationTokenXhrError = message => {
    return {type: types.CREATE_APPLICATION_TOKEN_ERROR, message};
};

export const deleteApplicationTokenXhrError = message => {
    return {type: types.DELETE_APPLICATION_TOKEN_ERROR, message};
};

export const alertsXhrError = message => {
    return {type: types.LOAD_ALERTS_TABLE_ERROR, message};
};

export const simManagementTableXhrError = message => {
    return {type: types.LOAD_SIM_MANAGEMENT_TABLE_ERROR, message};
};

export const simTagManagementTableXhrError = message => {
    return {type: types.LOAD_SIM_TAG_MANAGEMENT_TABLE_ERROR, message};
};


// Formats the message for screen render. I don't like that this lives here but I also don't want error handling too scatterred around.
export const showFailureMessage = message => {
    if(!message) { return; }

    switch(message) {
    case 'Network Error':
        return (<Row><Col><Alert color='warning'>A network error occurred when attempting to retrieve data. Please try again in a few minutes.</Alert></Col></Row>);
    default:
        return (<Row><Col><Alert color='danger'>An error occurred while retrieving data: {message}</Alert></Col></Row>);
    }
};