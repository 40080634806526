import {Auth} from 'aws-amplify';
import * as types from './actionTypes';
import {
    currentMonthFirstDay,
    nextMonthFirstDay,
    setLocalStorageCompanyAndOrganizationIdsForStyle,
    loadIBasisTokenInLocalStorage
} from '../common/utils';

import {loadMonthlySummary, loadMonthlySummaryTable} from '../actions/monthlySummaryActions';
import {loadESimInsightsTable} from '../actions/eSimInsightsAction';
import {loadESimManagementTable, loadESimManagementTableDefault} from '../actions/eSimManagementActions';
import {loadCustomerEsimStatus} from '../actions/customerEsimStatusSummaryActions';
import {loadAllowedCustomers} from '../actions/allowedCustomersActions';
import {loadAllowedCustomerTags} from '../actions/allowedCutomerTagsActions';
import {loadESimTagManagementTable} from '../actions/eSimTagManagementActions';
import { loadUserGroups } from '../actions/userGroupsActions';

export const cognitoUserAttributeActionSuccess = (userAttributes) => {
    return { type: types.COGNITO_ATTRIBUTES_LOADED_SUCCESS, userAttributes};
};

export const transformCognitoUserAttributeActionSuccess = (searchParameterData) => {
    return { type: types.COGNITO_ATTRIBUTES_TRANSFORMED_SUCCESS, searchParameterData};
};

export const applyOrganizationStyleToStoreActionSuccess = (styleInfo) => {
    return { type: types.SAVE_ORGANIZATION_STYLE_INFO_SUCCESS, styleInfo};
};

export const loadCognitoUserAttributes = () => {
    return async function(dispatch) {
        // eslint-disable-next-line no-unused-vars
        let hope = await loadIBasisTokenInLocalStorage();

        // eslint-disable-next-line no-unused-vars
        let creds = await Auth.currentCredentials();

        // call iot-auth for the allowed customers need to draw portion of navigation menu
        dispatch(loadAllowedCustomers());

        // add user's cognito groups to store for role management
        dispatch(loadUserGroups());

        return Auth.currentUserInfo().then(user => {
            // store the styles first in localStorage
            const companyAndOrganizationIdsForStyle = setLocalStorageCompanyAndOrganizationIdsForStyle(user);

            // dispatch style info to REDUX store
            dispatch(applyOrganizationStyleToStore(companyAndOrganizationIdsForStyle));
            dispatch(cognitoUserAttributeActionSuccess(user));
            dispatch(transformCognitoUserAttributeAction(user));


        }).catch((error) => {
            console.log(error);
            throw(error);
        });
    };
};

export const applyOrganizationStyleToStore = (companyAndOrganizationIdsForStyle) => {
    return function (dispatch, getState) {

        // check to see if the search parameters are already in the state...
        const {organizationStyleInfo} = getState();

        if (!organizationStyleInfo.hasOwnProperty('userOrganizationId')){
            const p = Promise.resolve(companyAndOrganizationIdsForStyle);
            return p.then((result) => dispatch(applyOrganizationStyleToStoreActionSuccess(result))).catch((error) => {
                console.log(error);
                throw (error);
            });
        }
    };
};

export const updateSessionWithNewUserIdData = (searchParameterData) => {
    return function (dispatch) {

        // call all the starting dispatch functions when there is a new userCustomerId selected from menu
        dispatch(loadMonthlySummary(searchParameterData));
        dispatch(loadMonthlySummaryTable(searchParameterData));
        dispatch(loadESimInsightsTable(searchParameterData));
        dispatch(loadESimManagementTable(searchParameterData));

        // loadCustomerEsimStatus - being called again because the userCustomerId has changed by way of menu selection
        dispatch(loadCustomerEsimStatus(searchParameterData));

        // call for the allowed tag name and tag values for the newly selected customer
        dispatch(loadAllowedCustomerTags(searchParameterData));

        // loadESimTagManagementTable - call for the user custom tags data to be updated
        dispatch(loadESimTagManagementTable(searchParameterData));
    };
};

export const transformCognitoUserAttributeAction = (user) => {
    return function (dispatch, getState) {

        // check to see if the search parameters are already in the state...
        const {transformCognitoUserAttributesSearchParameterData} = getState();

        if (!transformCognitoUserAttributesSearchParameterData.hasOwnProperty('userCustomerId')){

            // map the data
            const searchParameterData = {
                userCustomerId: user.attributes['custom:customer_id'],
                userOrganizationId: user.attributes['custom:org_id'],
                startDate: currentMonthFirstDay(),
                endDate: nextMonthFirstDay(),
                usageMonth: currentMonthFirstDay(),
            };

            // TODO - REMOVE - Just for testing
            dispatch(loadAllowedCustomerTags(searchParameterData));
            dispatch(loadESimTagManagementTable(searchParameterData));

            dispatch(loadMonthlySummary(searchParameterData));
            dispatch(loadMonthlySummaryTable(searchParameterData));
            dispatch(loadESimInsightsTable(searchParameterData));
            dispatch(loadESimManagementTableDefault(searchParameterData));

            // loadESimTagManagementTable - call for the user custom tags data to be updated
            dispatch(loadESimTagManagementTable(searchParameterData));

            // loadCustomerEsimStatus - should only be called once for the duration of the session
            dispatch(loadCustomerEsimStatus(searchParameterData));

            const p = Promise.resolve(searchParameterData);

            return p.then((result) => dispatch(transformCognitoUserAttributeActionSuccess(result))).catch((error) => {
                console.log(error);
                throw (error);
            });
        } else {
            const p = Promise.resolve(transformCognitoUserAttributesSearchParameterData);

            return p.then((result) => dispatch(transformCognitoUserAttributeActionSuccess(result))).catch((error) => {
                console.log(error);
                throw (error);
            });
        }
    };

};
