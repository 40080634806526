import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function eSimInsightsTableReducer(state = initialState.eSimInsightsTable, action) {
    switch (action.type) {
    case types.LOAD_ESIM_INSIGHTS_TABLE_SUCCESS:
        return action.eSimInsightsTable;
    case types.LOAD_ESIM_INSIGHTS_TABLE_BY_EID_SUCCESS:
        return action.eSimInsightsTable;
    case types.LOAD_ESIM_INSIGHTS_TABLE_BY_ICCID_SUCCESS:
        return action.eSimInsightsTable;
    case types.LOAD_ESIM_INSIGHTS_TABLE_BY_ALL_SUCCESS:
        return action.eSimInsightsTable;
    default:
        return state;
    }
}