import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function exportManagementTableReducer(state = initialState.exportManagement, action) {
    switch (action.type) {
    case types.LOAD_EXPORT_MANAGEMENT_TABLE_ALL_SUCCESS:
        return action.exportManagement;
    default:
        return state;
    }
}