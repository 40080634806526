import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function eSimDetailsReducer(state = initialState.eSimDetails, action) {
    switch (action.type) {
    case types.LOAD_SIM_DETAIL_BY_EID_SUCCESS:
        return action.eSimDetails;
    default:
        return state;
    }
}