/* istanbul ignore file */
import { combineReducers } from 'redux';
import monthlySummary from './monthlySummaryReducer';
import monthlySummaryTable from './monthlySummaryTableReducer';
import eSimInsightsTable from './eSimInsightsTableReducer';
import eSimManagementTable from './eSimManagementTableReducer';
import applicationTokensTable from './applicationTokensTableReducer';
import newBornApplicationToken from './createApplicationTokenReducer';
import deleteApplicationToken from './deleteApplicationTokenReducer';
import exportManagement from './exportManagementTableReducer';
import exportInsights from './exportInsightsTableReducer';
import dataLoading from './dataLoadingReducer';
import cognitoUserAttributes from './cognitoUserAttributeReducer';
import userDetails from './userDetailsReducer';
import transformCognitoUserAttributesSearchParameterData from './searchParameterDataReducer';
import customerEsimStatus from './customerEsimStatusReducer';
import xhrErrorMessage from './xhrErrorMessageReducer';
import organizationStyleInfo from './organizationStyleInfoReducer';
import allowedCustomers from './allowedCustomersReducer';
import apiDoc from './eSimApiDocReducer';
import simManagementTable from './simManagementTableReducer';
import allowedCustomerTags from './allowedCustomerTagsReducer';
import eSimTagsManagementTable from './eSimTagsManagementReducer';
import eSimTagsToUpdate from './updateTagsDataReducer';
import userGroups from './userGroupsReducer';
import userPreferredSettings from './userPreferredSettingsReducer';
import eSimDetails from './eSimDetailsReducer';

const rootReducer = (state, action) => {
    if(action.type === 'LOGOUT_USER') {
        state = undefined;
        // clear local storage
        localStorage.clear();
    }
    return appReducer(state, action);
};

const appReducer = combineReducers({
    cognitoUserAttributes,
    userGroups,
    organizationStyleInfo,
    transformCognitoUserAttributesSearchParameterData,
    monthlySummary,
    monthlySummaryTable,
    eSimInsightsTable,
    eSimManagementTable,
    applicationTokensTable,
    newBornApplicationToken,
    deleteApplicationToken,
    exportInsights,
    exportManagement,
    dataLoading,
    userDetails,
    xhrErrorMessage,
    customerEsimStatus,
    allowedCustomers,
    apiDoc,
    simManagementTable,
    allowedCustomerTags,
    eSimTagsManagementTable,
    eSimTagsToUpdate,
    userPreferredSettings,
    eSimDetails,
});

export default rootReducer;