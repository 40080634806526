import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function allowedCustomersReducer(state = initialState.allowedCustomers, action) {
    switch (action.type) {
    case types.LOAD_ALLOWED_CUSTOMERS_SUCCESS:
        return action.allowedCustomers;
    default:
        return state;
    }
}