import {API} from '../common/api';
import * as types from './actionTypes';

export const loadAllowedCustomerTagsSuccess = (allowedCustomerTags) => {
    return {type: types.LOAD_ALLOWED_CUSTOMER_TAGS_SUCCESS, allowedCustomerTags};
};

// thunk
export const loadAllowedCustomerTags = (searchParameterData) => {
    return function (dispatch) {
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/tags`).then(allowedCustomerTags => {
                dispatch(loadAllowedCustomerTagsSuccess(allowedCustomerTags.data));
        }).catch((error) => {
                console.log(error);
                throw(error);
            });
    };
};