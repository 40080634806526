import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function eSimApiDocReducer(state = initialState.apiDoc, action) {
    switch (action.type) {
    case types.LOAD_ESIM_API_DOC_SUCCESS:
        return action.apiDoc;
    default:
        return state;
    }
}