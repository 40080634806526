import axios from 'axios';

const BASE_URL = process.env.REACT_APP_SERVICE_URL;
const TIME_OUT = process.env.REACT_APP_SERVICE_XHR_TIME_OUT;
const DEFAULT_LOCAL_STORAGE_KEY = 'CognitoIdentityServiceProvider.Custom.iBasisToken';

export const API = (localStorageKey, header = {}) => {
    const KEY = localStorageKey || DEFAULT_LOCAL_STORAGE_KEY;  // by default look-up the iBasis Token
    const AUTH_TOKEN = localStorage.getItem(KEY);

    console.log('KEY:' + KEY);


    const DEFAULT_HEADERS = {
        'Authorization': AUTH_TOKEN,
        'Accept': 'application/json'
    };

    const HEADERS = Object.assign({}, DEFAULT_HEADERS, header);

    return axios.create({
        baseURL: BASE_URL,
        timeout: TIME_OUT,
        headers: HEADERS
    });
};