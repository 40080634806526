import * as types from '../actions/actionTypes';

export default function xhrErrorMessageReducer(state={}, action) {
    switch (action.type) {
        case types.LOAD_ESIM_MANAGEMENT_TABLE_ERROR:
            return {managementTableLoadErrorMessage: action.message};
        case types.LOAD_EXPORT_MANAGEMENT_TABLE_ERROR:
            return {managementTableLoadErrorMessage: action.message};
        case types.LOAD_ESIM_MANAGEMENT_TABLE_REQUEST:
            return {managementTableLoadErrorMessage: ''};
        case types.LOAD_EXPORT_MANAGEMENT_TABLE_REQUEST:
            return {managementTableLoadErrorMessage: ''};
        case types.LOAD_EXPORT_INSIGHTS_TABLE_REQUEST:
            return {insightTableLoadErrorMessage: ''};
        case types.LOAD_ESIM_INSIGHTS_TABLE_ERROR:
            return {insightTableLoadErrorMessage: action.message};
        case types.CREATE_APPLICATION_TOKEN_ERROR:
            return {applicationTokensTableLoadErrorMessage: action.message};
        case types.LOAD_APPLICATION_TOKENS_TABLE_ERROR:
            return {applicationTokensTableLoadErrorMessage: action.message};
        case types.DELETE_APPLICATION_TOKEN_ERROR:
            return {applicationTokensTableLoadErrorMessage: action.message};
        case types.LOAD_EXPORT_INSIGHTS_TABLE_ERROR:
            return {insightTableLoadErrorMessage: action.message};
        case types.LOAD_ESIM_INSIGHTS_TABLE_REQUEST:
            return {insightTableLoadErrorMessage: ''};
        case types.LOAD_ALERTS_TABLE_ERROR:
            return {alertsTableLoadErrorMessage: action.message};
        case types.LOAD_ALERTS_TABLE_REQUEST:
            return {alertsTableLoadErrorMessage: ''};
        default:
            return state;
    }
}
