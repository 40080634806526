import {API} from '../common/api';
import * as types from './actionTypes';
import {simTagManagementTableXhrError} from './xhrErrorActions';

const axiosTimeout = 0; // Wait forever

export const loadESimTagManagementTableSuccess = (eSimTagsManagementTable) => {
    return {type: types.LOAD_SIM_TAG_MANAGEMENT_TABLE_SUCCESS, eSimTagsManagementTable};
};

export const putESimTagManagementTableSuccess = (eSimTagsManagementTable) => {
    return {type: types.PUT_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS, eSimTagsManagementTable};
};

export const postESimTagManagementTableSuccess = (eSimTagsManagementTable) => {
    return {type: types.POST_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS, eSimTagsManagementTable};
};

export const deleteESimTagManagementTableSuccess = (eSimTagsManagementTable) => {
    return {type: types.DELETE_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS, eSimTagsManagementTable};
};

export const eSimTagManagementTableRequest = () => {
    return {type: types.LOAD_SIM_TAG_MANAGEMENT_TABLE_REQUEST};
};

// thunk
export const loadESimTagManagementTable = (searchParameterData) => {
    return function (dispatch) {
        dispatch(eSimTagManagementTableRequest());
        const axiosInstance = API();
        axiosInstance.defaults.timeout = axiosTimeout;
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/sims`)
            .then(eSimTagsManagementTable => {
                dispatch(loadESimTagManagementTableSuccess(eSimTagsManagementTable.data.sims));
            }).catch((error) => {
                dispatch(simTagManagementTableXhrError(error.message));
                console.log(error);
                throw(error);
            });
    };
};

export const putESimTagManagement = (searchParameterData) => {
    return async function (dispatch, getState) {
        const axiosInstance = API();
        axiosInstance.defaults.timeout = axiosTimeout;
        const eSimTagsToUpdate = getState().eSimTagsToUpdate;
        const x = { headers: {"Content-Type": "application/json"}};

        return await axiosInstance.put(`/customers/${searchParameterData.userCustomerId}/tags`, eSimTagsToUpdate, x)
            .then(() => {
                dispatch(putESimTagManagementTableSuccess());
            }).catch((error) => {
                console.log(error);
                throw(error);
            });
    };
};

export const postESimTagManagement = (searchParameterData) => {
    return async function (dispatch, getState) {
        const axiosInstance = API();
        axiosInstance.defaults.timeout = axiosTimeout;
        const eSimTagsToUpdate = getState().eSimTagsToUpdate;
        const x = { headers: {"Content-Type": "application/json"}};

        return await axiosInstance.post(`/customers/${searchParameterData.userCustomerId}/tags`, eSimTagsToUpdate, x)
            .then(() => {
                dispatch(postESimTagManagementTableSuccess());
            }).catch((error) => {
                console.log(error);
                throw(error);
            });
    };
};

export const deleteESimTagManagement = (searchParameterData, tagNameAsColumn) => {
    return async function (dispatch, getState) {
        const axiosInstance = API();
        axiosInstance.defaults.timeout = axiosTimeout;

        // combine data objects
        const headers = { headers: {"Content-Type": "application/json"}};
        const data = {data: {tagNameAsColumn : tagNameAsColumn}};
        const deletePackage = { headers, data};



        return await axiosInstance.delete(`/customers/${searchParameterData.userCustomerId}/tags`, {
            headers: {'Content-Type': 'application/json'},
               data: {name:tagNameAsColumn}})
            .then(() => {
                dispatch(deleteESimTagManagementTableSuccess());
            }).catch((error) => {
                console.log(error);
                throw(error);
            });
    };
};



export const loadESimTagManagementTableByStartingRange = (searchParameterData, resource_type, resource_value_start) => {
    return function (dispatch) {
        dispatch(eSimTagManagementTableRequest());
        const axiosInstance = API();
        axiosInstance.defaults.timeout = axiosTimeout;
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/sims?resource_type=${resource_type}&resource_value_start=${resource_value_start}`)
            .then(eSimTagsManagementTable => {
                dispatch(loadESimTagManagementTableSuccess(eSimTagsManagementTable.data.sims));
            }).catch((error) => {
                dispatch(simTagManagementTableXhrError(error.message));
                console.log(error);
                throw(error);
            });
    };
};