import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function customerEsimStatus(state = initialState.customerEsimStatus, action) {
    switch (action.type) {
    case types.LOAD_CUSTOMER_ESIM_STATUS_SUCCESS:
        return Object.assign({}, state, action.customerEsimStatus);
    default:
        return state;
    }
}