import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function searchParameterDataReducer(state = initialState.searchParameterData, action){
    switch (action.type) {
    case types.COGNITO_ATTRIBUTES_TRANSFORMED_SUCCESS:
        return action.searchParameterData;
    case types.SEARCH_PARAMETERS_UPDATED_SUCCESS:
        return Object.assign({}, state, action.searchParameterData);
    default:
        return state;
    }
}