/* istanbul ignore file */
import React, { Component } from 'react';
import {connect} from 'react-redux';
import {loadCognitoUserAttributes } from '../actions/cognitoUserAttributeActions';
import PropTypes from 'prop-types';
import {isObjectEmpty} from '../common/utils';

export default function asyncComponent(importComponent) {
    class AsyncComponent extends Component {
        constructor(props) {
            super(props);
            this.state = {
                component: null
            };
        }

        async componentDidMount() {
            const {user} = this.props;
            // only call when you do not have a valid user object from the REDUX store
            if (!user || typeof user !== 'undefined' || isObjectEmpty(user)) {
                this.props.loadUserAttributes()();
            }

            const { default: component } = await importComponent();
            this.setState({
                component: component
            });
        }

        render() {
            const C = this.state.component;
            return C ? <C {...this.props} /> : null;
        }
    }
    AsyncComponent.propTypes = {
        loadUserAttributes: PropTypes.func
    };

    function mapStateToProps(state){
        return {
            user:state.cognitoUserAttributes,
        };
    }
    
    const mapDispatchToProps = dispatch => ({
        loadUserAttributes() {
            return () => {
                loadCognitoUserAttributes()(dispatch);
            };
        },
    });
    
    return connect(mapStateToProps, mapDispatchToProps)(AsyncComponent);
}
