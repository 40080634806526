import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function applicationTokensTableReducer(state = initialState.applicationTokensTable, action) {
    switch (action.type) {
        case types.LOAD_APPLICATION_TOKENS_TABLE_SUCCESS:
            return action.applicationTokensTable;
        default:
            return state;
    }
}