import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function createApplicationTokenReducer(state = initialState.newBornApplicationToken, action) {
    switch (action.type) {
        case types.CREATE_APPLICATION_TOKEN_SUCCESS:
            return action.newBornApplicationToken;
        case types.CLEAN_NEW_BORN_APPLICATION_TOKEN:
            return '';
        default:
            return state;
    }
}