import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userPreferredSettingsReducer(state = initialState.userPreferredSettings, action) {
    console.log(`~~~~~~~~~~~~  userPreferredSettingsReducer ~~~~~~~~~~~~~`);
    switch (action.type) {
    case types.GET_CUSTOMER_SUPPORT_PREF_EMAILS_SUCCESS:
        return action.customerSupportEmails;
    default:
        return state;
    }
}