import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userGroupsReducer(state = initialState.userGroups, action){
    switch (action.type) {
    case types.USER_GROUPS_LOADED_SUCCESS:
        return action.userGroups;
    default:
        return state;
    }
}