import React from 'react';
import {connect} from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import asyncComponent from './components/AsyncComponent';
import {ConnectedRouter} from 'react-router-redux';
import {Route} from 'react-router';
import Amplify, {Analytics} from 'aws-amplify';
import {AuthPiece} from 'aws-amplify-react';

import history from './common/history';

import './base-theme.css';

const AsyncDashboardLayout = asyncComponent(() => import('./components/DashboardLayout'));
const AsyncManagementContainer = asyncComponent(() => import('./containers/ReportingContainer'));

const AsyncSimManagementContainer = asyncComponent(() => import('./containers/SimManagementContainer'));

const AsyncStaticInsightContainer = asyncComponent(() => import('./containers/StaticInsightContainer'));
const AsyncStaticConfig = asyncComponent(() => import('./components/simconfig/StaticConfig'));
const AsyncVersion = asyncComponent(() => import('./components/Version'));
const AsyncCustomerSupportContainer = asyncComponent(() => import('./containers/CustomerSupportContainer'));
const AsyncUserPreferredSettingsContainer = asyncComponent(() => import('./containers/UserPreferredSettingsContainer'));

const REACT_APP_AWS_REGION = process.env.REACT_APP_AWS_REGION; 
const REACT_APP_AWS_POOL_ID = process.env.REACT_APP_AWS_POOL_ID; 
const REACT_APP_COGNITO_APP_CLIENT_ID = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
const REACT_APP_IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID;

Analytics.configure({
    disabled:true
});
Amplify.configure({
    Auth: {
        region: REACT_APP_AWS_REGION,
        userPoolId: REACT_APP_AWS_POOL_ID,
        userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
        identityPoolId: REACT_APP_IDENTITY_POOL_ID
    }

});

class App extends AuthPiece {

    constructor(props) {
        super(props);
        this._validAuthStates = ['signedIn'];
    }

    showComponent(){
        return (
            <ConnectedRouter history={history}>
                <div>
                    <Route exact path="/" component={AsyncDashboardLayout}/>
                    <Route path="/dashboard" component={AsyncDashboardLayout}/>
                    <Route path="/reporting" component={AsyncManagementContainer}/>
                    <Route path="/simmanagement" component={AsyncSimManagementContainer}/>
                    <Route path="/insight/:eid?" component={AsyncStaticInsightContainer}/>
                    <Route path="/configure" component={AsyncStaticConfig}/>
                    <Route path="/version" component={AsyncVersion}/>
                    <Route path='/apidoc' component={() => { 
                        window.location.href = 'https://iotapi.globaliotdata.net/apidoc'; 
                        return null;
                    }}/>
                    <Route path='/support' component={AsyncCustomerSupportContainer}/>
                    <Route path='/userpreferences' component={AsyncUserPreferredSettingsContainer}/>
                </div>
            </ConnectedRouter>
        );
    }
}
export default connect()(App);