import moment from 'moment';
import {API} from './api';


export const currentMonthFirstDay = () => {
    return moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss');
};

export const nextMonthFirstDay = () => {
    return moment(moment().startOf('month').add(1,'M'),'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
};

export const monthSelectedFirstDay = (periods) => {
    let monthYear= periods.split(' ');
    let year =  monthYear[1];
    let month = moment().month(monthYear[0]).format('M')-1;
    let firstDay= moment([year,month,12]).startOf('month').format('D.M.YYYY');
    return firstDay;
};

export const monthSelectedLastDay = (periods) =>{
    let monthYear= periods.split(' ');
    let year =  monthYear[1];
    let month = moment().month(monthYear[0]).format('M')-1;
    let lastDay= moment([year,month,12]).endOf('month').format('D.M.YYYY');
    return lastDay;
};

export const  isEmpty = (value) => {
    return (value ? value : 'No Records');
};

export const isObjectEmpty= (Obj) => {
    if (!Obj || Obj === null)
        return true;

    if (typeof Obj === 'undefined')
        return true;

    if (typeof Obj === 'string' || typeof Obj === 'number' || typeof Obj === 'boolean' )
        return false;

    for(let key in Obj) {
        if(Obj.hasOwnProperty(key))
            return false;
    }
    return true;
};

const errorDefaultStyleInfo = {userOrganizationId: -1, logo: 'logo-clear.png', css: 'theme.css'};

export const setLocalStorageCompanyAndOrganizationIdsForStyle = (user) => {

    if (!hasValidUserObjectData(user)){
        console.warn(`User data not ready, setting defaults for style ${JSON.stringify(errorDefaultStyleInfo)}`);
        return errorDefaultStyleInfo;
    }

    const userCustomerId = user.attributes['custom:customer_id'];
    const userOrganizationId = user.attributes['custom:org_id'];
    const compositeKey = `${userOrganizationId}-${userCustomerId}`;

    const logo = `/logo-${compositeKey}.png`;
    const css =  `@import url('/theme-${compositeKey}.css')`;

    return  {userOrganizationId,logo,css};
};


export const hasValidUserObjectData = (user) => {
    if (typeof user === 'undefined' || typeof user.attributes['custom:customer_id'] === 'undefined' ||
        typeof user.attributes['custom:org_id'] === 'undefined'){
        return false;
    } else {
        return true;
    }
};


export const selectPeriods = () => {
    const NUMBER_OF_MONTHS_TO_CALCULATE = 13;
    let resultArrayOfDates = [];

    for (let index = 0; index <= NUMBER_OF_MONTHS_TO_CALCULATE; index++){
        const monthMMMMFormat = moment( moment().startOf('month').subtract(index,'M'),'YYYY-MM-DDTHH:mm:ss').format('MMMM YYYY');
        const dateIso8601Format = moment( moment().startOf('month').subtract(index,'M'),'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
        const data = { iso8601: dateIso8601Format, month: monthMMMMFormat};
        resultArrayOfDates.push(data);
    }
    return resultArrayOfDates;
};

export const addMonthsToDate = (dateString, numberOfMonthsToAdd = 0)  => {
    // parse the date - this will create a moment object
    const resultDate = moment(dateString).startOf('month').add(numberOfMonthsToAdd,'M');

    const monthMMMMFormat = resultDate.format('MMMM YYYY');
    const dateIso8601Format = resultDate.format('YYYY-MM-DDTHH:mm:ss');

    return  { iso8601: dateIso8601Format, month: monthMMMMFormat};
};

export const strSample = (key, value) => {
    localStorage.setItem(key, value);
};

export const getCognitoIdTokenForLoggedInUserFromLocalStorage = () => {
    const cognitoPrefix = 'CognitoIdentityServiceProvider';
    const cognitoLastAuthSuffix = 'LastAuthUser';
    const cognitoIdTokenSuffix = 'idToken';
    const COGNITO_APP_CLIENT_ID = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;

    console.log('getCognitoIdTokenForLoggedInUserFromLocalStorage - COGNITO_APP_CLIENT_ID: ' + COGNITO_APP_CLIENT_ID);

    console.log('JSON.stringify(process.env): ' +  JSON.stringify(process.env));


    const storageLookUpKeyLastAuthUser = `${cognitoPrefix}.${COGNITO_APP_CLIENT_ID}.${cognitoLastAuthSuffix}`;

    // get the last authorized user from local storage
    const lastAuthUser = localStorage.getItem(storageLookUpKeyLastAuthUser);

    // get the idToken from local storage
    return `${cognitoPrefix}.${COGNITO_APP_CLIENT_ID}.${lastAuthUser}.${cognitoIdTokenSuffix}`;
};

export const parseDateForMonthAndYear = (dateStringIso8601) => {
    // parse the date - this will create a moment object
    const resultDate = moment(dateStringIso8601);

    const yearYYYYFormat = resultDate.format('YYYY');
    const monthMMFormat = resultDate.format('MM');

    return  { month: monthMMFormat, year: yearYYYYFormat};
};

// Make a special call to our iot-auth server to get an temporary application key
async function load(localStorageKey){
    const axiosInstance = API(localStorageKey);  // for this to work it needs to be the cognito idToken "KEY"
    return await axiosInstance.post('/authenticate',{'x':null});  // IMPORTANT - wait for response from iot-auth
}

export async function loadIBasisTokenInLocalStorage() {
    // get the cognito LastAuthUser value from local storage
    const key = getCognitoIdTokenForLoggedInUserFromLocalStorage();

    console.log('getCognitoIdTokenForLoggedInUserFromLocalStorage" ' + key)

    // IMPORTANT - wait for response from iot-auth
    const {accessToken} = await load(key).then(function (result) {
        return result.data;
    });
    // Write the custom iBasis Token to the localStorage of the browser
    localStorage.setItem('CognitoIdentityServiceProvider.Custom.iBasisToken', accessToken);
}

export const customComparatorForColumnsAsc = (a, b) => {
    const tagNameA = a.name.toUpperCase();
    const tagNameB = b.name.toUpperCase();

    let comparison = 0;
    if (tagNameA > tagNameB) {
        comparison = 1;
    } else if (tagNameA < tagNameB) {
        comparison = -1;
    }
    return comparison;
};

export const transformDateISO8601ToDateObject = (responseData) => {
    if (responseData.length > 0) {
        return responseData.map(function (row) {
            if (row.lastTrafficDate) {
                return {...row, ...{lastTrafficDate: new Date( moment.utc(row.lastTrafficDate).format('L'))}};
            } else {
                return row;
            }
        });
    } else {
        return responseData;
    }
};

// https://stackoverflow.com/questions/586182/how-to-insert-an-item-into-an-array-at-a-specific-index-javascript
export const insertItemsIntoArray = (arr, index, ...newItems) => [
// part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index)
];

//https://stackoverflow.com/questions/9716468/pure-javascript-a-function-like-jquerys-isnumeric
export const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

export const addCustomValueToAllowedTags = (allowedTags, value) => {
    // clone - array
    let clone = [...allowedTags];

    clone.forEach((o) => {
        if(!o.values.includes(value)) {
            o.values.push(value);
        }
    });
    return clone;
};

export const escaped = (str) => {
    const estr = btoa(str);
    return estr.replace(/=/g, '_eq').replace(/\//g, '_slsh').replace(/\+/g, '_pls');
};

export const unescaped = (str) => {
    const ustr = str.replace(/_eq/g, '=').replace(/_slsh/g, '/').replace(/_pls/g, '+');
    return atob(ustr);
};

export const octetsToMegaBytes = (octets, SI) => {
    let result;
    let prettyResult;
    if (SI){
        result = octets / 1000000;
    } else {
        result = octets / 1048576;
    }
    prettyResult = result.toFixed(3) + ' MB';
    return prettyResult;
}