export default function dataLoadingReducer(state={}, action) {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

    if(!matches) {
        return state;
    }

    const [, requestName, requestState] = matches;

    // Not loading if error state. Otherwise toggle based on request or success.
    const loading = (requestState === 'ERROR') ? false : (requestState === 'REQUEST');
    
    return {
        ...state,
        [requestName]: loading
    };
}
