import * as types from './actionTypes';
import {esimManagementXhrError,exportManagementXhrError} from './xhrErrorActions';
import {API} from '../common/api';
import {transformDateISO8601ToDateObject} from '../common/utils';

export const loadESimManagementTableSuccess= (eSimManagementTable) => {
    return { type: types.LOAD_ESIM_MANAGEMENT_TABLE_SUCCESS, eSimManagementTable};
};

export const loadESimManagementTableFirst1000AllSuccess = (eSimManagementTable) => {
    return { type: types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_ALL_SUCCESS, eSimManagementTable};
};

export const loadESimManagementTableFirst1000ByEidSuccess = (eSimManagementTable) => {
    return { type: types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_EID_SUCCESS, eSimManagementTable};
};

export const loadESimManagementTableFirst1000ByDefaultICCIDSuccess = (eSimManagementTable) => {
    return { type: types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_DEFAULT_ICCID_SUCCESS, eSimManagementTable};
};

export const loadESimManagementTableFirst1000ByStatusSuccess = (eSimManagementTable) => {
    return { type: types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_STATUS_SUCCESS, eSimManagementTable};
};

export const loadESimManagementTableFirst1000ByEidAndStatusSuccess = (eSimManagementTable) => {
    return { type: types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_EID_AND_STATUS_SUCCESS, eSimManagementTable};
};

export const loadESimManagementTableFirst1000ByDefaultICCIDAndStatusSuccess = (eSimManagementTable) => {
    return { type: types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_DEFAULT_ICCID_AND_STATUS_SUCCESS, eSimManagementTable};
};

export const loadExportManagementTableAllSuccess = (exportManagement) => {
    return { type: types.LOAD_EXPORT_MANAGEMENT_TABLE_ALL_SUCCESS, exportManagement};
};


// leave this as is for now - yes this is stupid!
export const loadESimManagementTableDefault = (searchParameterData) => {
    return function (dispatch) {
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims`)
            .then(eSimManagementTableList => {
                dispatch(loadESimManagementTableSuccess(transformDateISO8601ToDateObject(eSimManagementTableList.data.content)));
            }).catch((error) => {
                dispatch(esimManagementXhrError(error.message));
            });
    };
};

export const loadESimManagementTable = (searchParameterData) => {
    return function (dispatch) {
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims`)
            .then(eSimManagementTableList => {
                dispatch(loadESimManagementTableSuccess(transformDateISO8601ToDateObject(eSimManagementTableList.data.content)));

            }).catch((error) => {
                dispatch(esimManagementXhrError(error.message));
            });
    };
};

export const loadAllESimManagementTableFirst1000All = (searchParameterData) => {
    return function (dispatch) {
        dispatch({type: types.LOAD_ESIM_MANAGEMENT_TABLE_REQUEST});
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims`)
            .then(eSimManagementTableList => {
                dispatch(loadESimManagementTableFirst1000AllSuccess(transformDateISO8601ToDateObject(eSimManagementTableList.data.content)));
            }).catch((error) => {
                dispatch(esimManagementXhrError(error.message));
            });
    };
};


export const loadESimManagementTableFirst1000ByEid = (searchParameterData) => {
    return function (dispatch) {
        dispatch({type: types.LOAD_ESIM_MANAGEMENT_TABLE_REQUEST});
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims?eid=${searchParameterData.esimId}`)
            .then(eSimManagementTableList => {
                dispatch(loadESimManagementTableFirst1000ByEidSuccess(transformDateISO8601ToDateObject(eSimManagementTableList.data.content)));
            }).catch((error) => {
                dispatch(esimManagementXhrError(error.message));
            });
    };
};

export const loadESimManagementTableFirst1000ByDefaultICCID = (searchParameterData) => {
    return function (dispatch) {
        dispatch({type: types.LOAD_ESIM_MANAGEMENT_TABLE_REQUEST});
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims?iccid=${searchParameterData.esimId}`)
            .then(eSimManagementTableList => {
                dispatch(loadESimManagementTableFirst1000ByDefaultICCIDSuccess(transformDateISO8601ToDateObject(eSimManagementTableList.data.content)));
            }).catch((error) => {
                dispatch(esimManagementXhrError(error.message));
            });
    };
};

export const loadESimManagementTableFirst1000ByStatus = (searchParameterData) => {
    return function (dispatch) {
        dispatch({type: types.LOAD_ESIM_MANAGEMENT_TABLE_REQUEST});
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims?status=${searchParameterData.simStatus}`)
            .then(eSimManagementTableList => {
                dispatch(loadESimManagementTableFirst1000ByStatusSuccess(transformDateISO8601ToDateObject(eSimManagementTableList.data.content)));
            }).catch((error) => {
                dispatch(esimManagementXhrError(error.message));
            });
    };
};

export const loadESimManagementTableFirst1000ByEidAndStatus = (searchParameterData) => {
    return function (dispatch) {
        dispatch({type: types.LOAD_ESIM_MANAGEMENT_TABLE_REQUEST});
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims?eid=${searchParameterData.esimId}&status=${searchParameterData.simStatus}`)
            .then(eSimManagementTableList => {
                dispatch(loadESimManagementTableFirst1000ByEidAndStatusSuccess(transformDateISO8601ToDateObject(eSimManagementTableList.data.content)));
            }).catch((error) => {
                dispatch(esimManagementXhrError(error.message));
            });
    };
};

export const loadESimManagementTableFirst1000ByDefaultICCIDAndStatus = (searchParameterData) => {
    return function (dispatch) {
        dispatch({type: types.LOAD_ESIM_MANAGEMENT_TABLE_REQUEST});
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims?iccid=${searchParameterData.esimId}&status=${searchParameterData.simStatus}`)
            .then(eSimManagementTableList => {
                dispatch(loadESimManagementTableFirst1000ByDefaultICCIDAndStatusSuccess(transformDateISO8601ToDateObject(eSimManagementTableList.data.content)));
            }).catch((error) => {
                dispatch(esimManagementXhrError(error.message));
            });
    };
};

export const loadExportManagementTableAll = (searchParameterData) => {
    return function (dispatch) {
        dispatch({type: types.LOAD_EXPORT_MANAGEMENT_TABLE_REQUEST});
        const axiosInstance = API(null, {'Accept': 'application/csv'});
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims`)
            .then(exportManagementTableList => {
                dispatch(loadExportManagementTableAllSuccess(exportManagementTableList.data));
            }).catch((error) => {
                dispatch(exportManagementXhrError(error.message));
            });
    };
};