import { Auth } from 'aws-amplify';
import * as types from './actionTypes';


export const userGroupsActionSuccess = (userGroups) => {
    return { type: types.USER_GROUPS_LOADED_SUCCESS, userGroups};
};

export const loadUserGroups = () => {
    return async function(dispatch) {
        return Auth.currentSession().then(session => {
            dispatch(userGroupsActionSuccess(session.getIdToken().payload['cognito:groups']));
        }).catch((error) => {
            console.log(error);
            throw(error);
        });
    };
};
