import {API} from '../common/api';
import * as types from './actionTypes';

export const loadCustomerEsimStatusSuccess = (customerEsimStatus) => {
    return {type: types.LOAD_CUSTOMER_ESIM_STATUS_SUCCESS, customerEsimStatus};
};

// thunk
export const loadCustomerEsimStatus = (searchParameterData) => {
    return function (dispatch) {
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims/provisioned`)
            .then(customerEsimStatus => {
                dispatch(loadCustomerEsimStatusSuccess(customerEsimStatus.data));
            }).catch((error) => {
                console.log(error);
                throw(error);
            });
    };
};
