export default {
    monthlySummary: [],
    monthlySummaryIndex: 0,
    monthlySummaryTable: [],
    eSimInsightsTable: [],
    exportInsights: [],
    eSimManagementTable: [],
    applicationTokensTable: [],
    newBornApplicationToken: '',
    exportManagement: [],
    userAttributes: {},
    userDetails: {},
    userGroups: [],
    alertsTable: [],
    user: {
        attributes: []
    },
    searchParameterData: {},
    customerEsimStatus: {},
    organizationStyleInfo: {},
    allowedCustomers: [],
    apiDoc: '',
    simManagementTable: [],
    allowedCustomerTags: [],
    eSimTagsManagementTable: [],
    eSimTagsToUpdate: {},
    userPreferredSettings: {email: []},
    eSimDetails: {
        eid: '',
        iccid: '',
        pin: '',
        puk: '',
        charging_state: '',
        catalog_service: [],
        actions: {
            InTransit: false,
            Inventory: false,
            Active: false,
            Suspended: false,
            Terminated: false,
            Terminate: false
        },
        usage: {
            total: '',
            lastBilling: '',
            currentBilling: '',
            lastWeek: '',
            currentWeek: '',
            yesterday: '',
            today: ''
        },
        quota_info: {
            eid: '',
            QuotaTotal: '',
            QuotaCurrent: '',
            QuotaMetrics: ''
        }
    },
};