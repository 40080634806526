import {API} from '../common/api';
import * as types from './actionTypes';

export const loadAllowedCustomersSuccess = (allowedCustomers) => {
    return {type: types.LOAD_ALLOWED_CUSTOMERS_SUCCESS, allowedCustomers};
};

// thunk
export const loadAllowedCustomers = () => {
    return function (dispatch) {
        const axiosInstance = API();
        return axiosInstance.get('/customers')
            .then(allowedCustomers => {
                dispatch(loadAllowedCustomersSuccess(allowedCustomers.data));
            }).catch((error) => {
                console.log(error);
                throw(error);
            });
    };
};