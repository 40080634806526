import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function cognitoUserAttributeReducer(state = initialState.user, action){
    switch (action.type) {
    case types.COGNITO_ATTRIBUTES_LOADED_SUCCESS:
        return action.userAttributes;
    default:
        return state;
    }
}