import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function eSimManagementTableReducer(state = initialState.eSimManagementTable, action) {
    switch (action.type) {
    case types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_ALL_SUCCESS:
        return action.eSimManagementTable;
    case types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_EID_SUCCESS:
        return action.eSimManagementTable;
    case types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_DEFAULT_ICCID_SUCCESS:
        return action.eSimManagementTable;
    case types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_STATUS_SUCCESS:
        return action.eSimManagementTable;
    case types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_EID_AND_STATUS_SUCCESS:
        return action.eSimManagementTable;      
    case types.LOAD_ESIM_MANAGEMENT_TABLE_FIRST_1000_BY_DEFAULT_ICCID_AND_STATUS_SUCCESS:
        return action.eSimManagementTable;
    default:
        return state;
    }
}