import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function eSimTagsToUpdateDataReducer(state = initialState.eSimTagsToUpdate, action){
    switch (action.type) {
    case types.STORE_SIM_TAG_MANAGEMENT_SELECTED_ITEMS_SUCCESS:
        return Object.assign({}, state, action.eSimTagsToUpdate);
    default:
        return state;
    }
}