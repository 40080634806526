import {API} from '../common/api';
import {parseDateForMonthAndYear} from '../common/utils';
import * as types from './actionTypes';

export const loadMonthlySummarySuccess = (monthlySummary) => {
    return {type: types.LOAD_CUSTOMER_MONTHLY_SUMMARY_SUCCESS, monthlySummary};
};

export const loadMonthlySummaryTableSuccess = (monthlySummaryTable) => {
    return {type: types.LOAD_CUSTOMER_MONTHLY_SUMMARY_TABLE_SUCCESS, monthlySummaryTable};
};

export const loadCustomerEsimStatusSuccess = (customerEsimStatus) => {
    return {type: types.LOAD_CUSTOMER_ESIM_STATUS_SUCCESS, customerEsimStatus};
};

// thunk
export const loadMonthlySummary = (searchParameterData) => {
    return function (dispatch) {
        const axiosInstance = API();
        const {month, year} = parseDateForMonthAndYear(searchParameterData.usageMonth);
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims/usage?month=${month}&year=${year}`)
            .then(monthlySummary => {
                dispatch(loadMonthlySummarySuccess(monthlySummary.data));
            }).catch((error) => {
                console.log(error);
                throw(error);
            });
    };
};

// thunk
export const loadMonthlySummaryTable = (searchParameterData) => {
    return function (dispatch) {
        const axiosInstance = API();
        const {month, year} = parseDateForMonthAndYear(searchParameterData.usageMonth);
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/stats/sims/usage-details?month=${month}&year=${year}`)
            .then(monthlySummaryTable => {
                dispatch(loadMonthlySummaryTableSuccess(monthlySummaryTable.data));
            }).catch((error) => {
                console.log(error);
                throw(error);
            });
    };
};