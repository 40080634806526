import {API} from '../common/api';
import * as types from './actionTypes';
import {esimInsightXhrError} from './xhrErrorActions';

export const loadESimInsightsTableSuccess = (eSimInsightsTable) => {
    return { type: types.LOAD_ESIM_INSIGHTS_TABLE_SUCCESS, eSimInsightsTable};
};

export const loadESimInsightsTableByEidSuccess = (eSimInsightsTable) => {
    return { type: types.LOAD_ESIM_INSIGHTS_TABLE_BY_EID_SUCCESS, eSimInsightsTable};
};


export const loadESimInsightsTableByIccidSuccess = (eSimInsightsTable) => {
    return { type: types.LOAD_ESIM_INSIGHTS_TABLE_BY_ICCID_SUCCESS, eSimInsightsTable};
};

export const loadESimInsightsTableByAllSuccess = (eSimInsightsTable) => {
    return { type: types.LOAD_ESIM_INSIGHTS_TABLE_BY_ALL_SUCCESS, eSimInsightsTable};
};

export const loadExportInsightsTableAllSuccess = (exportInsights) => {
    return { type: types.LOAD_EXPORT_INSIGHTS_TABLE_ALL_SUCCESS, exportInsights};
};

// thunk
export const loadESimInsightsTable = (searchParameterData) => {
    return function (dispatch) {
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/sims-usage?startUsageDate=${searchParameterData.startDate}&endUsageDate=${searchParameterData.endDate}`)
            .then(eSimInsightsTableList => {
                dispatch(loadESimInsightsTableSuccess(eSimInsightsTableList.data.content));
            }).catch((error) => {
                dispatch(esimInsightXhrError(error.message));
            });
    };
};

export const loadESimInsightsTableByEid = (searchParameterData) => {
    return function (dispatch) {	
        dispatch({type: types.LOAD_ESIM_INSIGHTS_TABLE_REQUEST});
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/sims-usage?startUsageDate=${searchParameterData.startDate}&endUsageDate=${searchParameterData.endDate}&eid=${searchParameterData.esimId}`)
            .then(eSimInsightsTableList => {
                dispatch(loadESimInsightsTableByEidSuccess(eSimInsightsTableList.data.content));
            }).catch((error) => {
                dispatch(esimInsightXhrError(error.message));
            });
    };
};

export const loadESimInsightsTableByIccid = (searchParameterData) => {
    return function (dispatch) {	
        dispatch({type: types.LOAD_ESIM_INSIGHTS_TABLE_REQUEST});
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/sims-usage?startUsageDate=${searchParameterData.startDate}&endUsageDate=${searchParameterData.endDate}&iccid=${searchParameterData.esimId}`)
            .then(eSimInsightsTableList => {
                dispatch(loadESimInsightsTableByIccidSuccess(eSimInsightsTableList.data.content));
            }).catch((error) => {
                dispatch(esimInsightXhrError(error.message));
            });
    };
};


export const loadESimInsightsTableByAll = (searchParameterData) => {
    return function (dispatch) {	
        dispatch({type: types.LOAD_ESIM_INSIGHTS_TABLE_REQUEST});
        const axiosInstance = API();
        return axiosInstance.get(`/customers/${searchParameterData.userCustomerId}/sims-usage?startUsageDate=${searchParameterData.startDate}&endUsageDate=${searchParameterData.endDate}`)
            .then(eSimInsightsTableList => {
                dispatch(loadESimInsightsTableByAllSuccess(eSimInsightsTableList.data.content));
            }).catch((error) => {
                dispatch(esimInsightXhrError(error.message));
            });
    };
};

export const loadExportInsightsTableAll = (searchParameterData) => {
    return function (dispatch) {
        dispatch({type: types.LOAD_EXPORT_INSIGHTS_TABLE_REQUEST});
        const axiosInstance = API(null, {'Accept': 'application/csv'});
        let url = `/customers/${searchParameterData.userCustomerId}/sims-usage?startUsageDate=${searchParameterData.startDate}&endUsageDate=${searchParameterData.endDate}`;
        if(searchParameterData && searchParameterData.searchIdValue && searchParameterData.searchIdValue.length>0) {
            url = `${url}&${searchParameterData.searchIdType.toLowerCase()}=${searchParameterData.searchIdValue}`;
        }
        return axiosInstance.get(url)
            .then(exportInsightsTableList => {                
                dispatch(loadExportInsightsTableAllSuccess(exportInsightsTableList.data));
            }).catch((error) => {
                dispatch(esimInsightXhrError(error.message));
            });
    };
};
