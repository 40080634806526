import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function customerMonthlySummaryTableReducer(state = initialState.monthlySummaryTable, action){
    switch (action.type) {
    case types.LOAD_CUSTOMER_MONTHLY_SUMMARY_TABLE_SUCCESS:
        return action.monthlySummaryTable;
    default:
        return state;
    }
}