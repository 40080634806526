import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function customerMonthlySummaryReducer(state = initialState.monthlySummary, action){

    switch (action.type) {
    case types.LOAD_CUSTOMER_MONTHLY_SUMMARY_SUCCESS:
        return Object.assign(action.monthlySummary);
    default:
        return state;
    }
}