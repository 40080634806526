import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function storeOrganizationStyleInfo(state = initialState.organizationStyleInfo, action) {
    switch (action.type) {
    case types.SAVE_ORGANIZATION_STYLE_INFO_SUCCESS:
        return Object.assign({}, state, action.styleInfo);
    default:
        return state;
    }
}