import {createBrowserHistory as createHistory} from 'history';

const history = createHistory();

// Get the current location.
const location = history.location;

// Listen for changes to the current location.
const unlisten = history.listen((location, action) => {
    const queryEntries = parseForQueryEntries(location);
    for (let param of queryEntries) {
        if (param[0] === 'userCustomerId') {

        }
    }
});

const parseForQueryEntries = (location) => {
    const query = new URLSearchParams(location.search);
    return query.entries();
}

export default history;

