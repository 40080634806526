import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import App from './app';
import { Authenticator, Greetings, SignIn } from 'aws-amplify-react';
import MySignIn from './components/auth/signin';
import logger from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import {createBrowserHistory as createHistory} from 'history';

const style = process.env.REACT_APP_STYLE;
const history = createHistory();
const router = routerMiddleware(history);
let middleware = [thunk, router, logger];


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore(initialState) {
    return createStore(
        rootReducer,
        initialState,
        composeEnhancer(applyMiddleware(...middleware))
    );
}

const store = configureStore();
  

ReactDOM.render(
    <div>
        <style>{'@import url(\'/' + style + '\')'}</style>
        <Provider store={store}>
            <Authenticator hide={[Greetings, SignIn]}>
                <MySignIn />
                <App />
            </Authenticator>
        </Provider>
    </div>,
    document.getElementById('root')
);

registerServiceWorker();